<template>
  <div class="accent">
    <v-container fluid>
      <v-row class="mx-auto py-4">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'Management' }">Management</router-link>
            | <span class="title">TAT Processes</span>
          </h1>
        </v-col>
      </v-row>

      <v-container>
        <v-row class="mt-10">
          <v-col class="mx-auto" cols="12" md="12">
            <tat-management-table></tat-management-table>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    tatManagementTable: () =>
      import('@/components/team-managment/TATManagementTable'),
  },
}
</script>
